declare global {
  interface String {
    OrDefault(defaultValue: string): string;
  }
}

String.prototype.OrDefault = function (defaultValue: string): string {
  return this && this.trim() ? this : defaultValue;
}

export const capitalize = (str = '', all: boolean = false) => {
  if (all) {
    const list = str.split(' ')
    return list.map((item) => capitalize(item)).join(' ')
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

export const TitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return capitalize(result)
}

export const pluralize = (count: number, noun: string | string[], suffix: string = 's') => {
  if (Array.isArray(noun)) {
    return `${count === 1 ? noun[0] : noun[1]}`
  } else {
    return `${noun}${count !== 1 ? suffix : ''}`
  }
}

export const StripHtmlTags = (data: string = '') => {
  const regex = /(<([^>]+)>)/gi
  return data.replace(regex, '')
}

export const isValidEmail = (value: string) => {
  if (!value) return false
  const reg = /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/
  return reg.test(value)
}

export const FormatPhoneNumber = (value: string = '') => {
  if (!value) return ''
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '')

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}
