import { DOMNode, Element } from 'html-react-parser'

const prefixes = ['ant', 'pv'] as const
const nonTextTags = ['a', 'button']

type MarkupRule = {
  targets: string[]
  rule: (elem: Element) => void
}

const findTextNodesRecursive = (node: DOMNode, textNodes: DOMNode[] = []) => {
  const elem = node as Element

  if (elem && !nonTextTags.includes(elem.name)) {
    if (node.type === 'text') {
      textNodes.push(node)
    } else {
      elem.children?.forEach((child) => findTextNodesRecursive(child as DOMNode, textNodes))
    }
  }

  return textNodes
}

const MarkupRules: MarkupRule[] = [
  {
    targets: ['a', 'button'],
    rule: (elem) => {
      const { attribs } = elem
      const classList = attribs.class ? attribs.class.split(' ') : []
      if (
        classList.some((i) => i.endsWith('-btn') && prefixes.some((p) => i.startsWith(p))) &&
        !classList.includes('pv-btn-variant-solid')
      ) {
        classList.push('pv-btn')
        classList.push('pv-btn-variant-solid')
        attribs.class = [...new Set(classList)].join(' ')
      }
    },
  },
  {
    targets: ['p'],
    rule: (elem) => {
      const textNodes = findTextNodesRecursive(elem)

      if (textNodes && textNodes.length) {
        textNodes.forEach((node) => {
          const { attribs } = node.parent as Element
          if (attribs) {
            const classList = attribs.class ? attribs.class.split(' ') : []
            classList.push('pv-typography-secondary')
            attribs.class = [...new Set(classList)].join(' ')
          }
        })
      }
    },
  },
  {
    targets: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    rule: (elem) => {
      const textNodes = findTextNodesRecursive(elem)

      if (textNodes && textNodes.length) {
        textNodes.forEach((node) => {
          const { attribs } = node.parent as Element
          if (attribs) {
            const classList = attribs.class ? attribs.class.split(' ') : []
            const styleList = attribs.style ? attribs.style.split(';') : []
            classList.push('pv-typography')

            styleList.push('font-family: var(--pv-heading-font-family)')
            styleList.push('font-weight: var(--pv-heading-font-weight)')

            attribs.class = [...new Set(classList)].join(' ')

            attribs.style = [...new Set(styleList)].join(';')
          }
        })
      }
    },
  },
]
function applyMarkupRule(node: DOMNode) {
  const elem = node as Element
  if (elem && elem.name) {
    const match = MarkupRules.find((r) => r.targets.includes(elem.name.toLowerCase()))
    if (match) {
      match.rule(elem)
    }
  }
  return node
}

export { applyMarkupRule }
