import { type CSSProperties, FC, type PropsWithChildren, useCallback } from 'react'
import { useCss } from 'react-use'

import { Flex, Spin, Typography } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import { useI18n } from '../../providers'
import { useColor } from '../app'
import IvyIcon from '../icon'

const TextComponent = Typography.Text

const Text: FC<SDK.Components.TextProps & { children: string }> = ({
  style: initialStyle,
  font = {
    size: undefined,
    weight: 400,
    family: 'primary',
  },
  type,
  size = 'middle',
  color: defaultColor,
  uppercased = false,
  rows,
  icon,
  iconAlign = 'start',
  iconSpace = 0,
  strong,
  strike = false,
  children = '',
  loading = false,
  underline,
  ...props
}) => {
  const { token, textByVariant } = useColor()
  const resolveSize = useCallback(
    (size: SDK.Components.TextProps['size']) => {
      switch (size) {
        case 'small':
          return token.fontSizeSM
        case 'middle':
          return token.fontSize
        case 'large':
          return token.fontSizeLG
        case undefined:
        default:
          return font.size
      }
    },
    [token.fontSizeSM, token.fontSize, token.fontSizeLG, font.size],
  )

  const fontSize = font?.size ?? resolveSize(size)
  const fontWeight = strong ? 600 : font?.weight
  const colorText = type ? textByVariant(type) : defaultColor || textByVariant('default')
  const fontFamily =
    font.family === 'inherit' ? 'inherit' : font?.family === 'primary' ? token.fontFamily : token.fontFamilySecondary
  const ellipsisClassName = useCss({
    ['display']: '-webkit-box',
    ['-webkit-line-clamp']: rows,
    ['-webkit-box-orient']: 'vertical',
    overflow: 'hidden',
    ['text-overflow']: 'ellipsis',
  })
  const style: CSSProperties = {
    fontSize,
    fontWeight,
    fontFamily,
    textTransform: uppercased ? 'uppercase' : 'initial',
    textDecoration: strike ? 'line-through' : underline ? 'underlined' : 'initial',
    flex: 1,
    color: colorText,
    ...initialStyle,
  }

  return (
    <Flex
      align={iconAlign}
      gap={12}
      justify={style.textAlign === 'right' ? 'end' : undefined}
      style={{ alignItems: 'center' }}
    >
      {!loading && icon && <IvyIcon type={icon} color={type} style={{ marginRight: iconSpace }} />}
      <TextComponent className={ellipsisClassName} data-cy={'Text'} style={style} {...props}>
        {children}
      </TextComponent>
      {loading && <Spin indicator={<LoadingOutlined spin />} size='small' />}
    </Flex>
  )
}

function withTranslation<P extends SDK.Components.TextProps & PropsWithChildren<{ translate?: 'yes' | 'no' }>>(
  Component: FC<Omit<P, 'translate' | 'context' | 'children'> & { children: string }>,
) {
  return ({ children, translate = 'yes', context, ...props }: P) => {
    const { t } = useI18n()
    // Possibly handle numbers here too
    if (typeof children !== 'string') return children
    if (!children) return null

    if (translate === 'no') return <Component {...props}>{children}</Component>
    return <Component {...props}>{t(children, context)}</Component>
  }
}

export default withTranslation(Text)
export { Text, withTranslation }
