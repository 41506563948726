import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/ja'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'

dayjs.locale('en')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export { dayjs, dayjs as default }
