import { FC } from 'react'

import { ConfigProvider, DatePickerProps, DatePicker as Wrapped } from 'antd'
import { createStyles } from 'antd-style'

import dayjs from 'dayjs'

import IvyIcon from '../icon'
import './DatePicker.css'

export const { RangePicker } = Wrapped

const useStyles = createStyles(({ css }: { css: any }) => ({
  noOutline: css`
    &.pv-picker {
      box-shadow: none !important;
    }
    &.pv-picker-focused {
      box-shadow: none !important;
    }
  `,
}))

// ConfigProvider added to prevent full width
// This issue was introduced when hashed/cssVar was disabled on the main App ConfigProvider
export const DatePicker: FC<DatePickerProps> = ({
  allowClear = false,
  picker = 'date',
  placeholder = 'Date',
  style,
  ...props
}) => {
  const { styles } = useStyles()
  return (
    <ConfigProvider
      theme={{
        hashed: true,
        cssVar: true,
      }}
    >
      <Wrapped
        style={{ width: '100%', ...style }}
        defaultValue={dayjs()}
        picker={picker}
        rootClassName={styles.noOutline}
        suffixIcon={<IvyIcon size={16} type={'custom/calendar'} />}
        allowClear={allowClear}
        {...props}
      />
    </ConfigProvider>
  )
}
