import { FC, PropsWithChildren } from 'react'

import { Badge, Flex } from 'antd'
import { createStyles } from 'antd-style'

import { useColor } from '../app'

export type BadgeProps = PropsWithChildren<{
  type: SDK.Components.ColorVariant
  responsive: boolean
}>

const useStyles = createStyles(({ token, css }: { token: SDK.Components.ThemeToken; css: any }) => ({
  default: css`
    color: ${token.cardBadgeColor} !important;
    background: ${token.cardBadgeBg} !important;
    font-family: ${token.fontFamilySecondary} !important;
    text-transform: uppercase !important;
    border-radius: ${token.borderRadius / 2} !important;
  `,
}))

export const CardBadge: FC<Partial<BadgeProps>> = ({ type = 'primary', children }) => {
  const { token } = useColor()

  const { styles } = useStyles()
  return (
    <Badge
      styles={{
        root: {
          height: 64,
          width: 64,
          textTransform: 'uppercase',
          fontWeight: 800,
        },
      }}
    >
      <Flex
        className={styles.default}
        vertical
        align={'center'}
        justify={'center'}
        style={{
          height: '100%',
          width: '100%',
          alignContent: 'center',
          fontWeight: 800,
          color: token.cardBadgeColor,
          fontSize: 16,
          borderRadius: token.borderRadius / 2,
          backgroundColor: token.cardBadgeBg,
        }}
      >
        {children}
      </Flex>
    </Badge>
  )
}
