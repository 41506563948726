import { createRoot } from 'react-dom/client'

// import initMsw from '@peoplevine/sdk/mocks'
import './assets/global.scss'
import RootProvider from './containers/RootProvider'
import { initSentry } from './containers/Sentry'

initSentry()
//
// if (import.meta.env.REACT_APP_MSW_ENABLED) {
//   initMsw()
//   console.log('msw enabled')
// }

createRoot(document.querySelector('#root')!).render(<RootProvider />)
