import { ResourceGenerics, RestEndpoint, RestGenerics, schema as RestSchema, resource } from '@data-client/rest'

import camelcaseKeys from 'camelcase-keys'
import ls from 'localstorage-slim'
import snakecaseKeys from 'snakecase-keys'

import { ApiError } from '../../api'
import { adminAxios, privateAxios, publicAxios } from '../../services/axios'
import { PaginatedHeader } from './pagination'

const language = (ls.get<string>('locale') ? ls.get<string>('locale') : 'en-US')?.split('-')[0]

class ApiEndpoint<O extends RestGenerics = any> extends RestEndpoint<O> {
  axiosInstance(url?: string) {
    if (url?.startsWith('/api/public')) {
      return publicAxios
    } else if (url?.startsWith('/api/admin')) {
      return adminAxios
    } else {
      return privateAxios
    }
  }

  static sanitizeParams<
    T extends {
      [key: string]: string | Array<string | number> | number | boolean | undefined | null
    },
  >(args: T): API.SearchParams {
    return Object.entries(args)
      .filter(([, v]) => typeof v !== 'undefined' && v !== undefined && typeof v !== null)
      .reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: Array.isArray(v) ? v.map(String).join(',') : v && v.toString(),
        }),
        {},
      )
  }

  url<T extends API.Params>(...args: T[]): string {
    const sanitized = args.map((it) => ApiEndpoint.sanitizeParams(it))
    return super.url(...(snakecaseKeys(sanitized, { deep: true }) as never))
  }

  async getRequestInit(body: any) {
    if (body) {
      if (body instanceof File) {
        const formData = new FormData()
        formData.append('file', body)
        body = formData
      } else {
        return super.getRequestInit(typeof body !== 'string' ? snakecaseKeys(body) : body)
      }
    }

    const requestInit: RequestInit = await super.getRequestInit(body)
    requestInit.credentials = 'include'
    return requestInit
  }

  async fetchResponse(input: RequestInfo, init: RequestInit): Promise<any> {
    const url = typeof input === 'string' ? input.replace('/singleton', '') : input.url
    const instance = this.axiosInstance(url)

    try {
      return await instance.request({
        url: url,
        method: init.method,
        data: init.body,
        headers: {
          ...(init.headers as any),
          'Accept-Language': language,
        },
      })
    } catch (err) {
      const response = err?.response
      const requestId = response.headers['x-pv-request-id']
      throw new ApiError({ ...response, requestId })
    }
  }

  async parseResponse(response: any) {
    if (!response.status.toString().startsWith('2')) {

      throw new ApiError(response.data)
    }
    return response.data ?? []
  }

  process<T>(value: any, ...args: any): T {
    return value ? camelcaseKeys(value, { deep: true, exclude: ['messages'] }) : {}
  }
}

// Possibly introduce Queryable
// https://dataclient.io/docs/api/useQuery
function PaginationSchema<S>(schema: S) {
  return {
    results: new RestSchema.Collection([schema], {
      nonFilterArgumentKeys: ['orderBy', 'pageSize', 'pageNumber'],
    }),
    pagination: new PaginatedHeader(),
  }
}


function paginationExtension<S, P extends API.PaginationParams & API.SortingParams>({
  schema,
  searchParams,
}: {
  schema: S
  searchParams?: P
}) {

  return {
    searchParams: {} as P & API.PaginationParams & API.SortingParams & Record<string, any>,
    schema: PaginationSchema(schema),
    async parseResponse(response: any) {
      const data = response.data
      const results: S[] = data ?? []
      const pagination_header = response.headers?.['pagination'] ?? response.headers?.['Pagination'] ?? null

      let pagination: PaginatedHeader = new PaginatedHeader()

      if (pagination_header) {
        pagination = JSON.parse(pagination_header) as PaginatedHeader
      } else {
        pagination = {
          pageNumber: 1,
          pageSize: results.length,
          totalPages: 1,
          totalCount: results.length,
          hasPreviousPage: false,
          hasNextPage: false,
        }
      }
      return { results, pagination }
    },
  }
}

// schema is the entity schema
// listSchema is the schema for the list - This is useful when the GET request of the schema returns more data than the list
// Example can be found in src/datasource/transaction.ts - StatementSummary / StatementEntity
const createApiResource = <
  S,
  LS = S,
  P = API.Params,
>({
  path = '',
  searchParams,
  Endpoint = ApiEndpoint,
  schema,
  listSchema = schema as LS,
  ...params
}: ResourceGenerics & {
  readonly schema: S
  readonly listSchema?: LS
  readonly searchParams?: P
  readonly Endpoint?: typeof ApiEndpoint
}) => {
  return resource({
    path,
    schema,
    Endpoint,
    searchParams,
    optimistic: true,
    ...params,
  })
    .extend({
      get: {
        schema: schema,
      },
      getList: {
        schema: new RestSchema.Collection([listSchema]),
      },
    })
    .extend((BaseResource) => ({
      create: BaseResource.getList.push.extend({
        schema: schema,
      }),
      paginatedList: BaseResource.getList.extend({
        ...paginationExtension({
          schema: listSchema,
          searchParams: BaseResource.getList.searchParams,
        }),
      }),
      infinitePagination: BaseResource.getList.extend({
        ...paginationExtension({
          schema: listSchema,
        }),
        paginationField: 'pageNumber',
      }),
    }))
}

export { ApiEndpoint, createApiResource, paginationExtension }
