import type { FC, ReactNode } from 'react'

import { Descriptions as BaseDescriptions, Flex } from 'antd'

import Text from '..//text/Text'
import IvyIcon from '../icon'
import { Title } from '../text/Title'

type DescriptionsProps = {
  title: ReactNode
  titleLevel?: 1 | 2 | 3 | 4 | 5
  style?: React.CSSProperties
  extra?: ReactNode
  centered?: boolean
} & Data.Source<SDK.Components.DescriptionItem[]>

const Descriptions: FC<Partial<DescriptionsProps>> = ({ data = [], title, titleLevel = 5, style, centered, extra }) => {
  return (
    <BaseDescriptions
      style={style}
      title={
        <Flex justify={'space-between'}>
          {typeof title === 'string' ? (
            <Title rows={1} level={titleLevel}>
              {title}
            </Title>
          ) : (
            title
          )}
          {extra}
        </Flex>
      }
      column={1}
      size={'small'}
      colon={false}
      contentStyle={
        centered
          ? {
              alignItems: 'center',
              justifyContent: 'center',
            }
          : undefined
      }
      items={data
        .filter((it) => it.text)
        .map(({ icon, text }, index) => ({
          style: { paddingBottom: 4 },
          children: (
            <Flex gap={8} align={'center'}>
              {icon && <IvyIcon type={icon} color={'secondary'} />}
              {typeof text === 'string' ? (
                <Text key={index} type={'secondary'}>
                  {text}
                </Text>
              ) : (
                text
              )}
            </Flex>
          ),
        }))}
    />
  )
}

export { Descriptions }
