import ApiEntity from '../../api/entity'
import { OrderEntity } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountBooking, AccountBookingSummary } from './booking'

class AccountReservation extends AccountBooking {
  readonly type: API.AccountBookingType = 'reservations'

}

class CancelReservationResponse extends ApiEntity implements API.CancelBookingResponse {
  static schema = {
    order: OrderEntity,
    refunds: [TransactionRefundItem],
    booking: AccountBookingSummary,
  }
  orderId?: Data.ID
  order?: OrderEntity
  refunds?: TransactionRefundItem[]
  amount: number
  message: string
  success: boolean
  booking?: AccountBookingSummary

  type: API.AccountBookingType = 'reservations'
  pk() {
    return `${this.id}`
  }
}

export { AccountReservation, CancelReservationResponse }
