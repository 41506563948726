import { Entity } from '@data-client/rest'

import { BaseAddress } from '../address'
import { ApiEntity, Singleton } from '../api/entity'
import { AbstractTokenEntity } from '../token'

class AccountPhone {
  countryCode?: string
  number: string = ''
  full: string = ''

  get format() {
    if (this.countryCode) {
      const pattern = /(\d{3})(\d{3})(\d{4})$/
      const formatted = this.number.replace(pattern, '($1) $2-$3')
      return this.countryCode ? `+${this.countryCode} ${formatted}` : formatted
    } else {
      return this.number.replace(
        /(\d)(\d{3})(\d{3})(\d{4})$/,
        this.number.length > 10 ? '+$1 ($2) $3-$4' : '($1) $2-$3',
      )
    }
  }
}

type AccountSocialProps = {
  facebook?: string
  twitter?: string
  google?: string
  instagram?: string
  foursquare?: string
  linkedin?: string
  tiktok?: string
}

export interface AccountRegister {
  firstName: string
  lastName: string
  email: string
  password: string
  advanced: AccountSettingsProps
  verifyCallbackUrl: string
}

export type AccountPasswordProps = {
  currentPassword?: string
  newPassword?: string
}

export type AccountUpdateProps = {
  firstName?: string
  lastName?: string
  middleName?: string
  birthDate?: Date
  mobileNumber?: string
  email?: string
  username?: string
  companyTitle?: string
  website?: string
  profilePhoto?: string
  gender?: 'U' | 'F' | 'M' | 'T'
  timeZone?: string
  social?: AccountSocialProps
  advanced?: AccountSettingsProps
  password?: AccountPasswordProps
}

export interface AccountIsRegistered {
  customerId: number
  activated: boolean
  firstName: string
  mobileNumber?: string
  email?: string
  isMember: boolean
}

export type AccountFlag = '' | 'createpw' | 'changepw' | 'membership_failed_payment' | 'membership_expired' | 'membership_suspended' | 'membership_cancelled' | 'membership_pending'

export type AccountSettingsProps = {
  optInEmail?: boolean
  optInSms?: boolean
  optInUpdate?: boolean
  private?: boolean
}

export interface IAccountEntity extends Data.Identified {
  readonly id: Data.ID
  readonly companyName?: string
  readonly companyTitle?: string
  readonly firstName: string
  readonly middleName?: string
  readonly lastName: string
  readonly fullName: string
  readonly mobile?: AccountPhone
  readonly phone?: AccountPhone
  readonly birthdate?: Date
  readonly email: string
  readonly profilePhoto?: string
}

class CustomerEntity extends ApiEntity implements IAccountEntity {
  readonly companyName?: string
  readonly companyTitle?: string
  readonly firstName: string
  readonly middleName?: string
  readonly lastName: string
  readonly fullName: string
  readonly mobile?: AccountPhone
  readonly phone?: AccountPhone
  readonly birthdate?: Date
  readonly email: string
  readonly profilePhoto?: string
  get name() {
    return this.fullName
  }
  get image(): string {
    return this.profilePhoto ? `${import.meta.env.REACT_APP_CDN_URL}${this.profilePhoto}` : ''
  }

  get initials(): string {
    return this.fullName
      .split(' ')
      .map((it) => it.slice(0, 1))
      .join('')
      .toUpperCase()
  }
}

class AccountRegistered extends Entity {
  readonly customerId: number = 0
  readonly activated: boolean = false
  readonly firstName: string = ''
  readonly mobileNumber?: string = ''
  readonly email?: string = ''
  readonly isMember: boolean = false

  pk() {
    return `${this.customerId}`
  }
}
class TokenEntity extends AbstractTokenEntity {
  readonly customer: AccountEntity = AccountEntity.fromJS({})
  readonly customerId: number = 0
}

class AccountEntity extends Singleton implements Data.Imaginated {
  static readonly urlRoot = '/api/account'

  readonly address: BaseAddress = BaseAddress.fromJS({})
  readonly birthdate: Date = new Date()
  readonly homeHouseId: number = 0
  readonly companyName: string = ''
  readonly companyTitle: string = ''
  readonly email: string = ''
  readonly firstName: string = ''
  readonly fullName: string = ''
  readonly gender: string = ''
  readonly lastName: string = ''
  readonly middleName: string = ''
  readonly mobile?: AccountPhone
  readonly phone?: AccountPhone
  readonly optInEmail: boolean = false
  readonly optInSms: boolean = false
  readonly private: boolean = false
  readonly profilePhoto: string = ''
  readonly totalTouchpoints: number = 0
  readonly userId: number = 0
  readonly website: string = ''
  readonly isMember: boolean = false
  readonly houseAccountEnabled: boolean = false
  readonly flag: AccountFlag = ''
  readonly advanced?: AccountSettingsProps
  readonly username: string

  get name(): string {
    return this.fullName
  }

  get images(): string[] {
    return [this.image]
  }

  get image(): string {
    return `${import.meta.env.REACT_APP_CDN_URL}${this.profilePhoto}`
  }

  get initials(): string {
    return this.fullName
      .split(' ')
      .map((it) => it.slice(0, 1))
      .join('')
      .toUpperCase()
  }
}

export { AccountEntity, AccountPhone, AccountRegistered, CustomerEntity, TokenEntity }
