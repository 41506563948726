
import ApiEntity from 'src/datasource/api/entity'
import { BaseAddress } from '../../address'
import { ImageEntity } from '../../media'
import { OrderEntity } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountTicket } from '../ticket'
import { AccountBooking, AccountBookingSummary } from './booking'

class AccountEventSummary extends AccountBooking {
  readonly summary: string = ''
  readonly venue: string = ''
  readonly venueAddress?: string
  readonly graphic: string = ''
  readonly photos: ImageEntity[] = []
  readonly type = 'events'
  readonly timezone_id: string = ''
  readonly uid: string = ''
  readonly registered: boolean = false
  readonly purchasedTickets?: AccountTicket[] = []

  get activeTickets(): AccountTicket[] {
    return this.purchasedTickets?.filter((t) => t.status !== 'cancelled' && t.status !== 'scanned') ?? []
  }

  get ticketQty(): number {
    return this.activeTickets ? this.activeTickets.length : 0
  }

  get transferredQty(): number {
    return this.activeTickets.filter((p) => p.transferred).length
  }

  get qty(): number {
    return this.ticketQty
  }

  get image(): string {
    return this.graphic
  }

  get locationName(): string {
    return this.venue
  }

  get address(): BaseAddress | undefined {
    const address = this.venueAddress
    if (!address) return undefined
    return BaseAddress.fromJS({
      address: this.venueAddress?.split(',')[0]?.trimStart() || '',
      city: address?.split(',')[1].trimStart() || '',
      state: address?.split(',')[2].trimStart() || '',
    })
  }

  static schema = {
    ...AccountBooking.schema,
    purchasedTickets: [AccountTicket],
  }
}

class CancelEventResponse extends ApiEntity implements API.CancelBookingResponse {
  orderId?: Data.ID
  refunds?: TransactionRefundItem[]
  amount: number = 0
  message: string = ''
  success: boolean = false
  orders?: OrderEntity[]
  booking?: AccountBookingSummary

  type: API.AccountBookingType = 'events'
  static schema = {
    order: OrderEntity,
    orders: [OrderEntity],
    refunds: [TransactionRefundItem],
    booking: AccountBookingSummary,
  }

  pk() {
    return `${this.id}`
  }
}

export { AccountEventSummary, CancelEventResponse }
