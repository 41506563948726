import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { Input } from 'antd'

import { useLoading } from '@data-client/hooks'

import { ApiError } from '@peoplevine/sdk/api'
import Form, { Button, Item } from '@peoplevine/sdk/components/form'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import { RenderOn } from '@peoplevine/sdk/components/screen'
import { AccountRegistered } from '@peoplevine/sdk/datasource/account/account'
import { isValidEmail } from '@peoplevine/sdk/helpers/strings'
import useBase64 from '@peoplevine/sdk/hooks/useBase64'
import { useI18n } from '@peoplevine/sdk/providers'
import { useAuth } from '@peoplevine/sdk/providers/Auth'
import { useConfig, withPrefix } from '@peoplevine/sdk/providers/ConfigProvider'
import { useNativeApp } from '@peoplevine/sdk/providers/NativeApp'
import { useNotification } from '@peoplevine/sdk/providers/Notification'
import routes from '@peoplevine/sdk/routes'

import { useActivate } from '../activate/ActivateProvider'

const LoginUsernameStep: FC<{
  onSuccess: (account: Omit<AccountRegistered, 'pk'> & { username: string }) => void
  onError: (account: Omit<AccountRegistered, 'pk'> & { username: string }) => void
}> = ({ onSuccess, onError }) => {
  const navigate = useNavigate()
  const { validateUser: validate } = useActivate()
  const { settings } = useConfig()
  const { t } = useI18n()
  const { notifyOnError } = useNotification()
  const handleSubmit = useCallback(
    async (values: { username: string }) => {
      const user = await validate(values.username)
      const type = isValidEmail(values.username) ? 'email' : 'username'
      if (!user) {
        if (settings.enableRegistration) {
          return navigate(`${routes.register}${type === 'email' ? `?email=${values.username}` : ''}`)
        } else {
          notifyOnError({
            name: `Invalid ${type}`,
            message: `No account matching the provided ${values.username} was found.`,
          })
        }
      } else {
        // Only active members can login
        if (settings.activeMembers) {
          if (user.isMember) {
            if (user.activated) {
              return onSuccess({ ...user, username: values.username })
            } else {
              // Not activated - show activation flow
              return onError({ ...user, username: values.username })
            }
          } else {
            // Not an active member
            notifyOnError({
              name: `Invalid ${type}`,
              message: `No account matching the provided ${values.username} was found.`,
            })
            return
          }
        } else {
          if (user.activated) {
            return onSuccess({ ...user, username: values.username })
          } else {
            // Not activated - show activation flow
            return onError({ ...user, username: values.username })
          }
        }
      }
    },
    [navigate, notifyOnError, onError, onSuccess, settings?.enableRegistration, settings?.activeMembers, validate],
  )
  const [onFinish, loading] = useLoading(handleSubmit, [handleSubmit])
  return (
    <Form<{ username: string }> name={'login'} layout={'vertical'} onFinish={onFinish}>
      <Item
        preserve
        name={'username'}
        label={'Email or Username'}
        required
        help={null}
        rules={[{ required: true, message: t('Please input your username or email') }]}
        style={{ marginBottom: 24 }}
      >
        <Input autoFocus type={'text'} autoComplete={'username'} />
      </Item>
      <Button loading={loading} block htmlType={'submit'}>
        Continue
      </Button>
    </Form>
  )
}
type FormValues = { password: string }

interface ILoginPasswordStep {
  username: string
  faceId: boolean
  onSuccess: VoidFunction
  onError: (error: ApiError) => void
}

const LoginPasswordStep: FC<ILoginPasswordStep> = ({ onSuccess, username, faceId }) => {
  const { authenticate } = useAuth()
  const { encode } = useBase64()
  const { dispatch } = useNativeApp()
  const [loading, setLoading] = useState(false)
  const { t } = useI18n()

  const onFormFinish = async (values: FormValues) => {
    setLoading(true)

    dispatch({
      type: 'LOGIN',
      body: {
        username,
        password: encode(values.password),
      },
    })
    await authenticate({
      username: username,
      password: values.password,
      rememberMe: true,
    })
      .then(onSuccess)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <VerticalSpace>
      <Form<FormValues> onFinish={onFormFinish}>
        <Item
          className={withPrefix('login-form-password')}
          preserve={false}
          name={'password'}
          label={'Password'}
          required
          rules={[{ required: true, message: t('Please input your password') }]}
          style={{ marginBottom: 24 }}
        >
          <Input.Password disabled={loading} autoComplete={'password'} />
        </Item>
        {faceId && (
          <RenderOn native>
            <Button
              className='login-form-face-id'
              type='link'
              onClick={() =>
                dispatch({
                  type: 'FACE_ID',
                })
              }
              icon={'ios/face-id'}
              htmlType={'button'}
              block
            >
              Use Face ID to log in
            </Button>
          </RenderOn>
        )}
        <Button loading={loading} block htmlType={'submit'}>
          Login
        </Button>
      </Form>
    </VerticalSpace>
  )
}

export { LoginPasswordStep, LoginUsernameStep }
