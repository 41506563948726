import { FC, MouseEvent, useCallback } from 'react'

import { Skeleton } from 'antd'
import { createStyles } from 'antd-style'

import parse, { HTMLReactParserOptions } from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'

import { useColor } from '../../app'
import './Markup.scss'
import { applyMarkupRule } from './MarkupRules'

type MarkupProps = SDK.Components.TextProps & {
  sanitized: boolean
  children: string
  terms?: boolean
  blog?: boolean
}

const useStyles = createStyles(({ token, css }: { token: any; css: any }) => ({
  markup: css`
    color: ${token.colorTextSecondary} !important;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${token.headingColor} !important;
      font-family: ${token.headingFontFamily} !important;
    }
  `,
}))

const Markup: FC<Partial<MarkupProps>> = ({
  sanitized = true,
  children,
  loading = false,
  terms = false,
  blog = false,
  type = 'secondary',
}) => {
  const { textByVariant } = useColor()
  const { styles } = useStyles()
  const parsedHTML = useCallback((html: string) => {
    const unescapeHTML = (html: string) => html.replace(/\\"/g, '"').replace(/\\'/g, "'")
    const options: HTMLReactParserOptions = {
      replace: applyMarkupRule,
    }
    return parse(unescapeHTML(html), options)
  }, [])

  const scrollToElement = (scrollId: string) => {
    const targetElement =
      (document.querySelector(`[name="${scrollId}"]`) as HTMLElement) ||
      (document.getElementById(scrollId) as HTMLElement)
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 20,
        behavior: 'smooth',
      })
    }
  }

  const handleScrollTo = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLAnchorElement
    const href = target.getAttribute('href')

    if (href?.startsWith('#')) {
      const scrollId = href.substring(1)
      event.preventDefault()
      scrollToElement(scrollId)
    }
  }

  return loading ? (
    <Skeleton />
  ) : children ? (
    !sanitized ? (
      <div
        className={[terms ? 'terms' : blog ? 'blog' : 'markup', styles.markup].join(' ')}
        style={{ lineHeight: 1.5, color: textByVariant(type) }}
        data-cy='Markup'
        onClick={handleScrollTo}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(children.replaceAll('&nbsp;', ' ').trim(), {
            allowedTags: [
              'img',
              'p',
              'ul',
              'li',
              'ol',
              'span',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'div',
              'br',
              'a',
              'b',
              'i',
              'strong',
              'em',
            ],
            allowedAttributes: {
              a: ['href', 'name', 'data-scroll-target', 'target', 'class', 'style'],
            },
            allowedSchemes: ['http', 'https', 'mailto'],
          }),
        }}
      />
    ) : (
      <div className={[terms ? 'terms' : blog ? 'blog' : 'markup', styles.markup].join(' ')}>
        {parsedHTML(children)}
      </div>
    )
  ) : null
}

export { Markup }
export default Markup
