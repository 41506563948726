import { Entity } from '@data-client/rest'
import dayjs from 'dayjs'
import { createApiResource } from 'src/datasource/api/endpoint'
import { OrderEntity } from 'src/datasource/orders/order'
import { OrderItem } from 'src/datasource/orders/orderItem'
import { TransactionRefundItem } from 'src/datasource/transaction'
import { DateTz } from '../../../api'
import type { BaseAddress } from '../../address'
import { ApiEntity } from '../../api/entity'
import { BusinessLocation } from '../../business'
import { CompanyConfig } from '../../company'
import { SurveyEndpoint } from '../../survey'
import { CustomerEntity } from '../account'
import { AccountTicket } from '../ticket'

export interface AccountBookingFilter {
  type?: 'all' | 'appointment' | 'reservation' | 'event'
  upcoming?: string
  startDate?: string
  endDate?: string
  orderBy?: string
  date?: string
}

class AccountBookingSummary extends Entity implements Data.TemporalLocal {
  readonly status: API.AccountBookingStatus
  readonly cancelable: boolean = false
  readonly title: string = ''
  readonly subject: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly quantity: number = 1
  readonly waitlisted: boolean = false
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly type: API.AccountBookingType = 'appointments'
  readonly subType?: string
  readonly company?: CompanyConfig


  id: Data.ID = ''

  pk(): string {
    return `${this.id}`
  }

  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
    location: BusinessLocation,
  }

  get qty(): number {
    return this.quantity
  }

  get image(): string {
    return this.photo
  }
}

class AccountBooking extends AccountBookingSummary {
  readonly guest?: CustomerEntity
  readonly customer?: CustomerEntity
  readonly location?: BusinessLocation
  // Keep at least one property 'undefined' to ensure the detailed entity is fetched
  readonly survey?: SurveyEndpoint = undefined
  static schema = {
    ...AccountBookingSummary.schema,
    guest: CustomerEntity,
    customer: CustomerEntity,
    survey: SurveyEndpoint,
  }

  get address(): BaseAddress | undefined {
    return this.location?.address
  }

  get locationName(): string {
    return this.location?.name || ''
  }
}

function exclude<O extends Record<string, unknown>>(
  obj: O,
  keys: string[],
): Partial<O> {
  const r: any = {};
  Object.keys(obj).forEach(k => {
    if (!keys.includes(k)) r[k] = obj[k];
  });
  return r;
}

class CancelBookingResponse extends ApiEntity implements API.CancelBookingResponse {
  orderId?: Data.ID
  refunds?: TransactionRefundItem[]
  amount: number = 0
  message: string = ''
  success: boolean = false
  orders?: OrderEntity[]
  order?: OrderEntity
  booking?: AccountBookingSummary
  type: API.AccountBookingType = 'events'

  static schema = {
    orders: OrderEntity,
    order: OrderEntity,
    refunds: [TransactionRefundItem],
    booking: AccountBookingSummary,
  }
  pk() {
    return `${this.id}`
  }
}

const AccountBookingResource = createApiResource({
  path: '/api/account/bookings/:type?/:id',
  schema: AccountBooking,
  listSchema: AccountBookingSummary,
  searchParams: {} as AccountBookingFilter
}).extend('tickets', {
  path: '/api/account/events/:id/tickets',
  method: 'GET',
  schema: [AccountTicket],
})

OrderItem.schema = {
  booking: AccountBookingSummary,
}

export { AccountBooking, AccountBookingResource, AccountBookingSummary, CancelBookingResponse }
